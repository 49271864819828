.rdw-editor-wrapper:focus {
    outline: none;
}
.rdw-editor-wrapper {
    flex: auto;
    background: white;
    min-height: 50vh;
}
.rdw-editor-main {
    min-height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.rdw-editor-main blockquote {
    border-left: 5px solid #cccccc;
    padding-left: 5px;
}
.rdw-editor-main pre {
    background: #cccccc;
    border-radius: 3px;
    padding: 1px 10px;
}

.rdw-editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 2px solid #cccccc;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    user-select: none;
}

.DraftEditor-root {
    border: 2px solid #cccccc;
    min-height: 400px;
}
