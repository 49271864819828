@import "~antd/lib/style/themes/default";
@import "~antd/dist/antd";

@layout-body-background: #FFFFFF;
@layout-sider-background: #000000;
@menu-bg: #000000;
@menu-item-color: rgba(255, 255, 255, 0.65);
@menu-highlight-color: #fbc61e;
@menu-item-active-bg: #000000;
@menu-dark-submenu-bg: #999;

.ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: #333;
  width: 100% !important;
}

@layout-trigger-background: #fbc61e;

.trigger {
  background: #fbc61e;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  width: 100% !important;
}

a {
  color: #fbc61e;
}

@primary-color: #fbc61e;